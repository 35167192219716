import Vue from 'vue/dist/vue.min';
import {flow} from './components/flow';

export function vm(){
    const v = new Vue({
        el: '#appFlow',
        components: {
            'Flow': flow()
        },
    });
    const h = new Vue({
        el: '#site-header',
        data: {
          toggle: false
        }
    });
};