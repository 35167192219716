export function flow(){
    const temp = {
        data: function(){
            return {
                switch: false
            }
        },
        methods: {
            toggle: function(){
                this.switch = !this.switch
            }
        },
        template: 
        `
        <li @click="toggle()" :class="{'active': this.switch}">
            <div class="icon">
                <slot></slot>
            </div>
            <dl>
                <dt v-html="title"></dt>
                <dd v-html="desc"></dd>
            </dl>
        </li>
        `,
        props: ['title','desc']
    }
    return temp;
};