import "@babel/polyfill";
import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {index} from './index';
import {vm} from './vm';

let scene = [];
let controller;
controller =  new ScrollMagic.Controller();

document.addEventListener('DOMContentLoaded', function() {
  vm();
  scrolEvents(controller , scene);
});

window.onload = () => {
  index(controller , scene);
};