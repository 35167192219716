import Swiper from 'swiper/bundle';
import {TweenMax} from "gsap";
import {objectInit, Renderer} from './object3d';

export function index(){
  const f = document.getElementById('firstview')
  const b = document.getElementById('background')
  const i = document.getElementById('intro')

  if(f){
    objectInit();
  }
	setTimeout(() => {
		if(i){
			i.classList.add('remove')
			i.addEventListener('transitionend', () => {
				i.remove()
			});
		}
		if(f && b){
			f.classList.add('active')
			b.classList.add('active')
			setTimeout(() => {
        Renderer();
				f.classList.add('next')
				b.classList.add('next')
			}, 200);
		}
	}, 1800
  );

  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh',`${vh}px`)


	const archiveSlides = document.getElementById('archiveSlides');
	if(archiveSlides){
		new Swiper(archiveSlides, {
			slidesPerView: 'auto',
			loop: true,
			spaceBetween: 24,
			centeredSlides: false,
			parallax: false,
      pagination: {
        el: document.getElementById('pagenation'),
      },
		});
	}

	let follower = document.getElementById('follower')
	let followItem = document.querySelectorAll(".clickable")
	if(follower){
		let
			fWidth = 80,
			delay = 8,
			mouseX = 0,
			mouseY = 0,
			posX = 0,
			posY = 0;

		TweenMax.to({}, .01, {
			repeat: -1,
			onRepeat: function() {
				posX += (mouseX - posX) / delay
				posY += (mouseY - posY) / delay
				TweenMax.set(follower, {
					css: {
						left: posX - (fWidth / 2),
						top: posY - (fWidth / 2)
					}
				});
			}
		});
		document.body.addEventListener("mousemove", function(e){
			mouseX = e.clientX
			mouseY = e.clientY
		});

		for (let i = 0; i < followItem.length; i++) {
			followItem[i].removeEventListener("mouseenter", {el: follower, handleEvent: mouseEnter})
			followItem[i].removeEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
			followItem[i].addEventListener("mouseenter",{el: follower, handleEvent: mouseEnter})
			followItem[i].addEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
		}
	}

};

function mouseEnter(){
	this.el.classList.add('active');
}
function mouseLeave(){
	this.el.classList.remove('active');
}