import * as THREE from 'three'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';

let camera, scene, renderer, total = 400, pos = [], group
export function objectInit(){
  init();
}
export function Renderer(){
  initAnime();
  render();
}

function init() {
  class CUBE{
    objectData = {}
    constructor(name, w, h, d, color){
      this.name = name
      this.w = w
      this.h = h
      this.d = d
      this.color = color
    }
    createCube(){
      const geometry = new RoundedBoxGeometry(this.w, this.h, this.d, this.w, .4)
      const material = new THREE.MeshPhongMaterial({
        color: this.color
      })
      const cube = new THREE.Mesh(geometry, material );
      cube.name = this.name
      cube.castShadow = false
      cube.receiveShadow = true
      this.objectData = cube
    }
    positionSet(x, y, z){
      this.objectData.position.x = x
      this.objectData.position.y = y
      this.objectData.position.z = z
    }
  }

  let elem = document.getElementById('obj')
  scene = new THREE.Scene()
  scene.fog = new THREE.Fog(0xf4f4f8, 50, 200);

  const ambLight = new THREE.AmbientLight(0xFFFFFF, .96)
  scene.add(ambLight)

  camera = new THREE.PerspectiveCamera( 35, window.innerWidth / window.innerHeight, 1, 1000 )
  camera.position.x = 0
  camera.position.y = 0
  camera.position.z = 0
  scene.add(camera);

  // Create Shape SVG
  let obj = []
  let clone = []
  group = new THREE.Group()
  for(let i = 0; i < total; i++){
    const sc = rS()
    obj[i] = new CUBE(`obj#{$i}`, sc, sc, sc, rC())
    obj[i].createCube()
    obj[i].positionSet(-40,-40,-200)
    pos[i] = {
      x: rP(100),
      y: rP(50),
      z: rP(100, 40),
      r: rR(),
      obj: obj[i].objectData
    }
    scene.add(pos[i].obj)
  }

  for(let i = 0; i < total / 2; i++){
    const sc = rS()
    clone[i] =  new CUBE(`clone#{$i}`, sc, sc, sc, rC())
    clone[i].createCube()
    clone[i].positionSet(rP(120), rP(60), rP(120, 80))
    clone[i].objectData.rotation.z = rR()
    group.position.z = 200
    group.add(clone[i].objectData)
  }

  scene.add(group)


  renderer = new THREE.WebGLRenderer({antialias: true,  alpha: true})
  renderer.shadowMap.enabled = true
  renderer.outputEncoding = THREE.sRGBEncoding
  renderer.setPixelRatio( window.devicePixelRatio)
  renderer.setSize( window.innerWidth, window.innerHeight)

  let domElem = renderer.domElement
  elem.appendChild(domElem)

  window.addEventListener('resize', onWindowResize)
  window.addEventListener("mousemove",mouseHovEnter);
  //render()
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()
  renderer.setSize( window.innerWidth, window.innerHeight )
}

function render() {
  TWEEN.update()
  loopAnime()

  group.rotation.z -= 0.001
  camera.rotation.x -= .002
  camera.rotation.y += .002
  camera.scale.z = 1 - (1 * window.pageYOffset / 800)
  //camera.lookAt(new THREE.Vector3(0, 0, 400))
  renderer.render( scene, camera)
  window.requestAnimationFrame(render)
}

function initAnime() {
  TWEEN.removeAll();
  for(let i = 0; i < pos.length; i++){
    const rand = Math.random() * 400 + 4600
    new TWEEN.Tween(pos[i].obj.position)
      .to({x: pos[i].x, y: pos[i].y, z: pos[i].z}, rand)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start()
    new TWEEN.Tween(pos[i].obj.rotation)
      .to({z: pos[i].r}, rand)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start()
    new TWEEN.Tween(group.position)
      .to({z:0}, rand)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start()
  }
}

function loopAnime() {
  for(let i = 0; i < pos.length; i++){
    pos[i].obj.rotation.y += .02 * (i * Math.random() * .01)
    pos[i].obj.rotation.x += .02 * (i * Math.random() * .01)
  }
}

function rC(){
  const p = Math.random() * 100
  let result
  if(p > 84){      result = 0xe72929 } //赤
  else if(p > 72){ result = 0xe8501a } //ピンク
  else if(p > 60){ result = 0xf39800 } //橙
  else if(p > 48){ result = 0xf2b601 } //黄
  else if(p > 36){ result = 0x64b206 } //緑
  else if(p > 24){ result = 0x06b895 } //青緑
  else if(p > 12){ result = 0x099dd8 } //青
  else{            result = 0x0764e4 } //濃青

  return result
}


function rS(){
  return Math.random() * 3
}

function rP(c, d = 0){
  return Math.random() * (c + c ) - c + d;
}

function rR(){
  return r(Math.random() * (0 - 360) + 360);
}

function r(rad){
  return rad * (Math.PI/180)
}

function mouseHovEnter(e){
  let pageX = e.pageX;
  let pageY = e.pageY;
  let x = pageX - window.innerWidth/2;
  let y = pageY - window.innerHeight/2;
  document.documentElement.style.setProperty('--cx', `${ -1 * x / 2000 }`);
  document.documentElement.style.setProperty('--cy', `${ -1 * y / 2000 }`);
}

